html {
    background-color: #eef5f9;
}

form {
    padding: 20px;
    margin-top: 60px;
}

form button,
form h5 {
    margin: 20px;
}

.btn {
    background-color: #2663fc !important;
}

label.active {
    color: royalblue !important;
}

input:focus {
    border-bottom: 1px solid #2663fc !important;
    box-shadow: 0 1px 0 0 #2663fc !important;
}

/* Navbar color */
.nav-wrapper {
    background-color: #2a4657 !important;
}

.brand-logo {
    padding-top: 5px !important;
}

.brand-logo img {
    height: 50px;
    width: 200px;
}

.head-col {
    width: 20%;
}

.w33 {
    width: 33%;
}

.w25 {
    width: 25%;
}

.w50 {
    width: 50%;
}

/* .productImage {
  height: 159px !important;
  width: 300px !important;
} */

.green-txt {
    color: green;
}

.red-txt {
    color: red;
}

.searchBox input[type='search'] {
    width: 30%;
}

.scan-card {
    height: 400px;
    margin: auto;
    padding: 100px;
}

.h-center {
    text-align: center;
}

.h-center div {
    display: inline-block;
}

.s-info {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 27px;
}

tr td:first-child {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.qr-code-main {
    display: flex;
    padding: 15px;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.qr-code-main button {
    margin-right: 0px;
}

.cancel-button button {
    background-color: #ffff !important;
    color: black;
    border: none;
}
.modal-backdrop.fade.show {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -99;
}
.qr-image {
    width: 200px;
}

.qr-code-modal {
    z-index: 100;
}
.modal .modal-content {
    width: 400px !important;
    height: 400px !important;
    background-color: white !important;
    border-radius: 5px !important;
}
