form {
  margin-top: 0px;
}

.btn {
  margin: 0 20px 0 0;
}

.login-form {
  padding: 0;
}

ul.product-data {
  margin: 10px 0;
  padding: 0;
  list-style-type: disc;
  list-style-position: inside;
}

ul.product-data li {
  list-style-type: disc;
}

.row {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 30px !important;
}

.map {
  height: 500px;
}

.tableIcon {
  cursor: pointer;
}

.delete-icon-color {
  color: red;
}

.verified-icon-color {
  color: green;
}

.disable-icon {
  color: red;
}

.disable-icon :hover {
  cursor: not-allowed;
}

.margin-top-20 {
  margin-top: 20px;
}
